import React from 'react';
import SEO from '../../components/seo';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  Grid,
  CircularProgress,
  Button,
  InputAdornment,
  Typography,
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  TextField,
  makeStyles,
  RadioGroup,
  Radio,
  createStyles,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import Fade from 'react-reveal/Fade';
import { navigate } from 'gatsby';
import axios from 'axios';
import SectionContainerLayoutWithFilter from '../../components/section-container-layout-with-filter';
import Layout from '../../components/layout';
import ImageContainer from '../../components/image-container';
import CustomLink from '../../components/custom-link/index';
import FeaturedCardStatisticNew from '../../components/featured-card-statistic/featured-card-statistic-new';
const ContactUsSchema = Yup.object().shape({
  firstName: Yup.string().min(2, 'Too Short !').max(30, 'Too Long!').required('First name is required'),
  lastName: Yup.string().min(2, 'Too Short !').max(30, 'Too Long!').required('Second name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  confirmedEmail: Yup.string()
    .email('Invalid email')
    .required('Email is required')
    .oneOf([Yup.ref('email'), null], 'Email must match'),
  company: Yup.string().min(2, 'Too Short!').max(30, 'Too Long!').required('Company name is required'),
  jobTitle: Yup.string().min(2, 'Too Short!').max(30, 'Too Long!').required('Job title is required'),
  message: Yup.string().min(3, 'Too Short!').max(300, 'Too Long!').required('Message is required'),
  isTermsAndConditions: Yup.boolean().oneOf([true], 'Agreement to the terms and conditions is required'),
  reason: Yup.string().required(),
});

const useStyles = makeStyles((theme) =>
  createStyles({
    textFieldLabelSpacing: {
      '& .MuiFormLabel-root': {
        marginLeft: '10px',
      },
      '&.MuiFormControlLabel-root': {
        marginRight: '0px',
      },
    },
  })
);

const CONTACTUS_CARDS = [
  {
    icon: 'hours',
    text: 'Working Hours',
    description: 'Moday to Friday \n (9am-6pm)',
  },

  {
    icon: 'location',
    text: 'Office Location',
    description: '5104, Aspin Commercial Tower, Dubai',
  },

  {
    icon: 'number',
    text: 'Contact Number',
    description: '045149504 \n',
  },
];

const ContactUsPage = (props) => {
  const classes = useStyles();
  async function handleFormikSubmit(values, actions) {
    try {
      const response = await axios.post(process.env.GATSBY_API_URL + 'contact-us', values);
      // const responsePardot = await axios.post('https://cors-anywhere.herokuapp.com/https://go.pardot.com/l/917773/2021-03-09/myt', values);

      if (response.status === 200) {
        actions.setSubmitting(false);
        navigate('/contact-us/thankyou?pi_list_email=' + values.email);
      }
    } catch (err) {
      console.log(err);
    }
    // navigate('/contact-us/thankyou');
  }
  return (
    <Layout>
      <SEO
        lang='en'
        title='Contact Us | Global Manufacturing & Industrialisation Summit'
        keywords='GMIS Summit 2022 Contact us, Contact us for Dubai Industrial & Innovation summits 2022, Join global industry GMIS Summit, Contact us  For Global Innovation summits Connect for virtual industries Summit UAE'
        description='Contact us for joining Dubai manufacturing, Industrial & Innovation summits 2022. Check out the latest global Innovation summits on GMIS UAE.'
      />
      <SectionContainerLayoutWithFilter title='CONTACT US'>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                confirmedEmail: '',
                company: '',
                jobTitle: '',
                message: '',
                isTermsAndConditions: false,
                isSubscribe: false,
                reason: '',
              }}
              validationSchema={ContactUsSchema}
              onSubmit={handleFormikSubmit}>
              {({
                errors,
                touched,
                isSubmitting,
                values,
                setFieldValue,
                setFieldTouched,
                handleBlur,
                handleChange,
              }) => {
                return (
                  <Fade bottom>
                    <form
                      autoComplete='off'
                      action='https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8'
                      method='POST'>
                      <input type='hidden' name='oid' value='00D5A0000016Qjw' />
                      <input type='hidden' name='retURL' value='https://gmisummit.com/contact-us/thankyou' />

                      <Grid container>
                        <Grid item xs={12}>
                          <Box width={1} mb={4}>
                            <TextField
                              className={classes.textFieldLabelSpacing}
                              name='first_name'
                              label='First Name'
                              type='text'
                              value={values.firstName}
                              error={errors.firstName && touched.firstName}
                              helperText={touched.firstName && errors.firstName ? errors.firstName : ''}
                              onChange={(e) => setFieldValue('firstName', e.target.value)}
                              onBlur={() => setFieldTouched('firstName')}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='start'>
                                    <Typography variant='body1'>*</Typography>
                                  </InputAdornment>
                                ),
                              }}
                              fullWidth
                            />
                          </Box>
                          <Box width={1} mb={4}>
                            <TextField
                              className={classes.textFieldLabelSpacing}
                              name='last_name'
                              label='Last Name'
                              type='text'
                              value={values.lastName}
                              error={errors.lastName && touched.lastName}
                              helperText={touched.lastName && errors.lastName ? errors.lastName : ''}
                              onChange={(e) => setFieldValue('lastName', e.target.value)}
                              onBlur={() => setFieldTouched('lastName')}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='start'>
                                    <Typography variant='body1'>*</Typography>
                                  </InputAdornment>
                                ),
                              }}
                              fullWidth
                            />
                          </Box>
                          <Box width={1} mb={4}>
                            <TextField
                              className={classes.textFieldLabelSpacing}
                              type='text'
                              name='email'
                              label='Email'
                              error={errors.email && touched.email}
                              value={values.email}
                              helperText={touched.email && errors.email ? errors.email : ''}
                              onChange={(e) => setFieldValue('email', e.target.value)}
                              onBlur={() => setFieldTouched('email')}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='start'>
                                    <Typography variant='body1'>*</Typography>
                                  </InputAdornment>
                                ),
                              }}
                              fullWidth
                            />
                          </Box>
                          <Box width={1} mb={4}>
                            <TextField
                              className={classes.textFieldLabelSpacing}
                              type='text'
                              label='Confirm Email'
                              value={values.confirmedEmail}
                              error={errors.confirmedEmail && touched.confirmedEmail}
                              helperText={touched.confirmedEmail && errors.confirmedEmail ? errors.confirmedEmail : ''}
                              onChange={(e) => setFieldValue('confirmedEmail', e.target.value)}
                              onBlur={() => setFieldTouched('confirmedEmail')}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='start'>
                                    <Typography variant='body1'>*</Typography>
                                  </InputAdornment>
                                ),
                              }}
                              fullWidth
                            />
                          </Box>
                          <Box width={1} mb={4}>
                            <TextField
                              className={classes.textFieldLabelSpacing}
                              type='text'
                              name='00N5A00000LPeUN'
                              label='Company Name'
                              error={errors.company && touched.company}
                              value={values.company}
                              helperText={touched.company && errors.company ? errors.company : ''}
                              onChange={(e) => setFieldValue('company', e.target.value)}
                              onBlur={() => setFieldTouched('company')}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='start'>
                                    <Typography variant='body1'>*</Typography>
                                  </InputAdornment>
                                ),
                              }}
                              fullWidth
                            />
                          </Box>
                          <Box width={1} mb={4}>
                            <FormControl fullWidth>
                              <InputLabel id='00N5A00000LPN43' style={{ marginLeft: '0.5rem' }}>
                                Job Role
                              </InputLabel>
                              <Select
                                onChange={(e) => setFieldValue('jobTitle', e.target.value)}
                                onBlur={() => setFieldTouched('jobTitle')}
                                value={values.jobTitle}
                                helperText={touched.jobTitle && errors.jobTitle ? errors.jobTitle : ''}
                                error={errors.jobTitle && touched.jobTitle}
                                id='00N5A00000LPN43'
                                name='00N5A00000LPN43'>
                                <MenuItem value='Owner / Chairman / President'>Owner / Chairman / President</MenuItem>
                                <MenuItem value='C-Level (CEO, COO, CFO... etc)'>
                                  C-Level (CEO, COO, CFO... etc)
                                </MenuItem>
                                <MenuItem value='Managing Director / General Manager'>
                                  Managing Director / General Manager
                                </MenuItem>
                                <MenuItem value='Department Head / Senior Manager'>
                                  Department Head / Senior Manager
                                </MenuItem>
                                <MenuItem value='Junior Manager / Executive'>Junior Manager / Executive</MenuItem>
                                <MenuItem value='Admin / Coordinator / Secretary / PA'>
                                  Admin / Coordinator / Secretary / PA
                                </MenuItem>
                                <MenuItem value='Journalist'>Journalist</MenuItem>
                                <MenuItem value='Professor / University Employee'>
                                  Professor / University Employee
                                </MenuItem>
                                <MenuItem value='Student'>Student</MenuItem>
                                <MenuItem value='Other'>Other</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                          <Box width={1} mb={4}>
                            <TextField
                              className={classes.textFieldLabelSpacing}
                              type='text'
                              name='00N6e00000Mehcz'
                              label='Message'
                              multiline
                              error={errors.message && touched.message}
                              value={values.message}
                              helperText={touched.message && errors.message ? errors.message : ''}
                              onChange={(e) => setFieldValue('message', e.target.value)}
                              onBlur={() => setFieldTouched('message')}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='start'>
                                    <Typography variant='body1'>*</Typography>
                                  </InputAdornment>
                                ),
                              }}
                              fullWidth
                            />
                          </Box>
                          <Box width={1} mb={4}>
                            <FormControl fullWidth>
                              <InputLabel id='00N6e00000Mehcp' style={{ marginLeft: '0.5rem' }}>
                                Reason of Inquiry
                              </InputLabel>
                              <Select
                                onChange={(e) => setFieldValue('reason', e.target.value)}
                                onBlur={() => setFieldTouched('reason')}
                                value={values.reason}
                                helperText={touched.reason && errors.reason ? errors.reason : ''}
                                error={errors.reason && touched.reason}
                                id='00N6e00000Mehcp'
                                name='00N6e00000Mehcp'>
                                <MenuItem value=''>
                                  <em>None</em>
                                </MenuItem>
                                <MenuItem value='Marketing &amp; PR'>Marketing &amp; PR</MenuItem>
                                <MenuItem value='Exhibition'>Exhibition</MenuItem>
                                <MenuItem value='Conference &amp; Speakers'>Conference &amp; Speakers</MenuItem>
                                <MenuItem value='Delegate and General Inquiries'>
                                  Delegate and General Inquiries
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                          <Box width={1} mb={1}>
                            <FormControl
                              error={touched.isTermsAndConditions && errors.isTermsAndConditions ? true : false}>
                              <FormControlLabel
                                className={classes.textFieldLabelSpacing}
                                value={values.isTermsAndConditions}
                                name='isTermsAndConditions'
                                control={<Checkbox onChange={handleChange} onBlur={handleBlur} />}
                                label={
                                  <Typography variant='body2'>
                                    I agree to the <span> </span>
                                    <CustomLink
                                      linkText='Terms and Conditions *'
                                      external
                                      type='2'
                                      linkUrl='/terms-and-conditions'
                                    />
                                  </Typography>
                                }
                              />
                              <FormHelperText>
                                {touched.isTermsAndConditions && errors.isTermsAndConditions
                                  ? errors.isTermsAndConditions
                                  : ''}
                              </FormHelperText>
                            </FormControl>
                          </Box>

                          <Box width={1} mb={4}>
                            <FormControl>
                              <FormControlLabel
                                className={classes.textFieldLabelSpacing}
                                value={values.isSubscribe}
                                name='isSubscribe'
                                control={<Checkbox onChange={handleChange} onBlur={handleBlur} />}
                                label={<Typography variant='body2'>Subscribe to GMIS Newsletter</Typography>}
                              />
                            </FormControl>
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={2}>
                          <Box mt={1}>
                            <Button disabled={isSubmitting} fullWidth type='submit' variant='contained' color='primary'>
                              Submit
                            </Button>
                            {isSubmitting && <CircularProgress size={24} />}
                          </Box>
                        </Grid>
                      </Grid>
                    </form>
                  </Fade>
                );
              }}
            </Formik>
            {/* )} */}
          </Grid>
          <Grid item xs={12} md={6}>
            <Fade bottom>
              <Box display={{ xs: 'none', md: 'block' }}>
                <ImageContainer filename='contact-us-new-2022' altText='Contact-us' />
              </Box>
              <Box>
                <Grid container spacing={3}>
                  {CONTACTUS_CARDS.map((crd) => {
                    return (
                      <Grid item xs={12} sm={4} md={4}>
                        <Box mt={3}>
                          <FeaturedCardStatisticNew data={crd} isData />
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Fade>
          </Grid>
        </Grid>
      </SectionContainerLayoutWithFilter>
    </Layout>
  );
};

export default ContactUsPage;
